import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { IPushNotificationsState } from '../../@types/pushNotifications';

const initialState: IPushNotificationsState = {
  isLoading: false,
  error: null,
  notifications: [],
};

const slice = createSlice({
  name: 'pushNotifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for customer is
    },
    getMissedNotificationsSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.notifications = action.payload;
    },
  },
});

export default slice.reducer;

export function getMissedNotifications(user_id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/get_missed_notifications/${user_id}`);
      if (response.status === 404) {
        throw new Error('User not found');
      } else {
        dispatch(slice.actions.getMissedNotificationsSuccess(response));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
// ----------------------------------------------------------------------
