import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

// @mui
import {
  IconButton,
  Typography,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Slider,
} from '@mui/material';

// components
import { useSnackbar } from '../../../components/snackbar';
import { fCurrency } from '../../../utils/formatNumber';
import { getCurrencyLabel } from '../../../assets/data/currency';

import Iconify from '../../../components/iconify';
import { IInvoice } from '../../../@types/invoice';
import { useLocales } from 'src/locales';

// redux
import { useDispatch } from '../../../redux/store';
import { orderExtraCredits } from '../../../redux/slices/invoice';

type Props = {
  width?: number;
  height?: number;
  latestCustomerOrder: IInvoice | null;
  navbar: boolean;
};

export default function ExtraCredits({ latestCustomerOrder, navbar }: Props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [quantity, setQuantity] = useState(10);
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const handleOrderedExtraCredits = async () => {
    try {
      if (latestCustomerOrder) {
        if (quantity < 1 || quantity > 50) {
          enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
        }
        const response = await dispatch(orderExtraCredits(latestCustomerOrder?.id, quantity));
        setOpenDialog(true);
        if (response) {
          // Success case
        } else {
          enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
        }
      } else {
        enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
        setOpenDialog(true);
      }
    } catch (err) {
      enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
      setOpenDialog(true);
    }
  };

  if (!latestCustomerOrder) {
    return <></>;
  }

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setQuantity(value);
    }
  };

  const totalPrice = quantity * (latestCustomerOrder?.unit_price || 0);

  return (
    <>
      <Button
        onClick={() => {
          setOpenDialog(true);
        }}
        variant="outlined"
        startIcon={<Iconify icon="ph:basket" />}
      >
        {`${translate('customer.membership.buyExtraCredits')}`}
      </Button>
      <Dialog fullWidth maxWidth="md" open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {`${translate('customer.membership.buyExtraCredits')}`}
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            color="error"
            onClick={() => setOpenDialog(false)}
          >
            <Iconify icon="ic:sharp-close" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: '100%', p: 3 }}>
            <Typography id="input-slider" gutterBottom>
              {`${translate('customer.membership.quantityExtraCredits')}`}:
            </Typography>

            <Slider
              value={quantity}
              aria-labelledby="input-slider"
              step={1}
              marks
              min={1}
              max={50}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              sx={{
                marginBottom: 2,
                '& .MuiSlider-valueLabel': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            />

            <Typography variant="subtitle2">
              {`${translate('customer.membership.hourlyPrice')}`}:{' '}
              {`
                    ${fCurrency(latestCustomerOrder?.unit_price || 0)} 
                    ${getCurrencyLabel(latestCustomerOrder?.currency)}
                  `}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontStyle: 'italic',
                color: theme.palette.groundingGrey.main,
              }}
            >
              *{`${translate('customer.membership.expires4weeks')}`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {`${translate('customer.membership.totalPrice')}`}:{' '}
            {`
                    ${fCurrency(totalPrice || 0)} 
                    ${getCurrencyLabel(latestCustomerOrder?.currency)}
                  `}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenDialog(false);
              handleOrderedExtraCredits();
            }}
          >
            {`${translate('customer.membership.goToPayment')}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
