// import orderBy from 'lodash/orderBy';
import { EventInput } from '@fullcalendar/core';
import { useLocales } from 'src/locales';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Stack,
  Tooltip,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
// utils

// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

type Props = {
  lessons: EventInput[];
  onResetFilter: VoidFunction;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (value: Date | null) => void;
  setEndDate: (value: Date | null) => void;
  onSelectLesson: (lessonId: string) => void;
  sortOption?: 'newest' | 'oldest' | 'default';
  onSort?: (option: 'newest' | 'oldest' | 'default') => void;
};

export default function SelectPeriod({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onResetFilter,
  sortOption = 'default',
  onSort,
}: Props) {
  const { translate } = useLocales();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={1}
      alignItems="center"
      sx={{ mb: 2, p: 2 }}
    >
      <DatePicker
        label={`${translate('tablePeriodSelector.start')}`}
        value={startDate}
        onChange={setStartDate}
        renderInput={(params) => <TextField size="small" {...params} />}
      />

      <DatePicker
        label={`${translate('tablePeriodSelector.end')}`}
        value={endDate}
        onChange={setEndDate}
        renderInput={(params) => <TextField size="small" {...params} />}
      />

      {onSort && (
        <FormControl
          sx={{
            minWidth: 120,
            '& .MuiOutlinedInput-root': {
              height: 40,
            },
          }}
        >
          <Select
            value={sortOption}
            onChange={(e) => onSort(e.target.value as 'newest' | 'oldest' | 'default')}
            size="small"
            displayEmpty
          >
            <MenuItem value="default">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify icon="mdi:sort" />
                <span>{translate('tablePeriodSelector.sort.default') as unknown as string}</span>
              </Stack>
            </MenuItem>
            <MenuItem value="newest">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify icon="mdi:sort-descending" />
                <span>{translate('tablePeriodSelector.sort.newest') as unknown as string}</span>
              </Stack>
            </MenuItem>
            <MenuItem value="oldest">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify icon="mdi:sort-ascending" />
                <span>{translate('tablePeriodSelector.sort.oldest') as unknown as string}</span>
              </Stack>
            </MenuItem>
          </Select>
        </FormControl>
      )}

      <Tooltip title="Reset filters">
        <Box sx={{ position: 'relative' }}>
          <IconButton onClick={onResetFilter}>
            <Iconify icon="ic:round-refresh" />
          </IconButton>
        </Box>
      </Tooltip>
    </Stack>
  );
}
