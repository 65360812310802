import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { ILessonState, ILesson, ILessonTable, ILessonPlan } from '../../@types/lesson';
import { ICalendarEvent } from 'src/@types/calendar';

const initialState: ILessonState = {
  isLoading: false,
  isLoadingLessonPlan: false,
  isLoadingLessonExercises: false,
  error: null,
  lessons: [],
  userLessons: [],
  unapprovedLessons: [],
  lessonPlan: null,
  lesson: null,
  recording: null,
};
const slice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    startLoadingLessonPlan(state) {
      state.isLoadingLessonPlan = true;
      state.error = null;
    },
    startLoadingExercises(state) {
      state.isLoadingLessonExercises = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isLoadingLessonPlan = false;
      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for lesson is
    }, // GET Lesosns
    getLessonSuccess(state, action) {
      state.isLoading = false;
      state.lesson = action.payload;
    },
    getLessonsSuccess(state, action) {
      state.isLoading = false;
      state.lessons = action.payload;
    },
    getUserLessonsSuccess(state, action) {
      state.isLoading = false;
      state.userLessons = action.payload;
    },
    getLessonRecodingsSuccess(state, action) {
      state.isLoading = false;
      state.recording = action.payload;
    },
    // UPDATE EVENT
    updateLessonApproved(state, action) {
      state.isLoading = false;
      state.error = null;

      if (state.userLessons !== null) {
        // Find the lesson in the userLessons array and update its approved status
        const index = state.userLessons.findIndex((lesson) => lesson.id === action.payload.id);
        if (index !== -1) {
          state.userLessons[index] = {
            ...state.userLessons[index],
            approved: action.payload.approved,
          };
        }
      }
    },
    getUnapprovedLessonsSuccess(state, action) {
      state.isLoading = false;
      state.unapprovedLessons = action.payload;
    },
    getLessonPlan(state, action) {
      state.isLoadingLessonPlan = false;
      state.isLoadingLessonExercises = false;
      state.error = null;
      state.lessonPlan = action.payload;
    },
    getLessonPlanExercises(state, action) {
      state.error = null;
      state.isLoadingLessonExercises = false;
      state.lessonPlan = state.lessonPlan && {
        ...state.lessonPlan,
        exercises: [...state.lessonPlan.exercises, ...action.payload],
      };
    },
    deleteLessonPlanSuccess(state, action) {
      state.isLoadingLessonPlan = false;
      state.error = null;
      state.lessonPlan = null;
    },
  },
});

export default slice.reducer;

export function getLesson(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/lesson/${id}`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Lesson not found');
      } else {
        dispatch(slice.actions.getLessonSuccess(response.data));
        // console.log(slice);
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function sendLessonRemainder(id: string | undefined) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`/api/send_reminder/${id || ''}`);
      if (response.status === 404) {
        throw new Error('Lesson not found');
      } else {
        return true;
        // console.log(slice);
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getLessons() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/lesson`);
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Lesson not found');
      } else {
        const mappedData = response.data.data.map((data: ILessonTable) => {
          return {
            ...data,
            student: data.student_,
            teacher: {
              id: data.teacher_id,
              first_name: data.teacher_first_name,
              last_name: data.teacher_last_name,
            },
            from_time: new Date(`${data.from_time.toString()}Z`),
            to_time: new Date(`${data.to_time.toString()}Z`),
          };
        });
        dispatch(slice.actions.getLessonsSuccess(mappedData));
        return true;
        // console.log(slice);
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
/**
 *
 * @param id  Id of the user account of the student
 * @param start_date Lessons between this date, this is the first date
 * @param end_date Lessons between this date, this is the last date
 * @returns array of lessons and assings them to the state
 */
export function getLessonsForStudent(
  id: string | number,
  start_date: string | undefined,
  end_date: string | undefined
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    // console.log('herer si the id for lessonsForStudent', id);
    const url =
      start_date && end_date
        ? `/api/lessons-student/${id}?start_date=${start_date}&end_date=${end_date}&status=scheduled`
        : `/api/lessons-student/${id}`;
    try {
      const response = await axios.get(url, {
        withCredentials: true,
      });
      // console.log(response);
      const configuredTimeArray = response.data.data.map((lesson: ICalendarEvent) => {
        const teacherName = lesson.teacherName.split(' ');
        const studentName = lesson.studentName.split(' ');
        // console.log(lesson);
        return {
          ...lesson,
          from_time: new Date(`${lesson.from_time.toString()}Z`),
          to_time: new Date(`${lesson.to_time.toString()}Z`),
          teacher: {
            id: lesson.teacherId.code,
            first_name: teacherName[0],
            last_name: teacherName[1],
          },
          student: {
            id: lesson.studentId.code,
            first_name: studentName[0],
            last_name: studentName[1],
          },
        };
      });
      dispatch(slice.actions.getUserLessonsSuccess(configuredTimeArray));
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

/**
 *
 * @param id  Id of the user account of the student
 * @param start_date Lessons between this date, this is the first date
 * @param end_date Lessons between this date, this is the last date
 * @returns array of lessons and assings them to the state
 */
export function getLessonsForCustomer(
  id: string | number,
  start_date: string | undefined,
  end_date: string | undefined
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const url =
      start_date && end_date
        ? `/api/lessons-customer/${id}?start_date=${start_date}&end_date=${end_date}&status=scheduled`
        : `/api/lessons-customer/${id}`;
    try {
      const response = await axios.get(url, { withCredentials: true });
      const configuredTimeArray = response.data.data.map((lesson: ICalendarEvent) => {
        const teacherName = lesson.teacherName.split(' ');
        const studentName = lesson.studentName.split(' ');
        return {
          ...lesson,
          from_time: new Date(`${lesson.from_time.toString()}Z`),
          to_time: new Date(`${lesson.to_time.toString()}Z`),
          teacher: {
            id: lesson.teacherId.code,
            first_name: teacherName[0],
            last_name: teacherName[1],
          },
          student: {
            id: lesson.studentId.code,
            first_name: studentName[0],
            last_name: studentName[1],
          },
        };
      });
      // console.log(response);
      dispatch(slice.actions.getUserLessonsSuccess(configuredTimeArray));
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

/**
 *
 * @param start_date Lessons between this date, this is the first date
 * @param end_date Lessons between this date, this is the last date
 * @returns array of lessons and assings them to the state
 */
export function getLessonsForAdmin(start_date: string | undefined, end_date: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    // console.log('herer si the id for lessonsForStudent', id);
    const url =
      start_date && end_date
        ? `/api/lessons-admin?start_date=${start_date}&end_date=${end_date}`
        : `/api/lessons-admin`;
    try {
      const response = await axios.get(url, { withCredentials: true });
      const configuredTimeArray = response.data.data.map((lesson: ICalendarEvent) => {
        const teacherName = lesson.teacherName.split(' ');
        const studentName = lesson.studentName.split(' ');
        return {
          ...lesson,
          from_time: new Date(`${lesson.from_time.toString()}Z`),
          to_time: new Date(`${lesson.to_time.toString()}Z`),
          teacher: {
            id: lesson.teacherId.code,
            first_name: teacherName[0],
            last_name: teacherName[1],
          },
          student: {
            id: lesson.studentId.code,
            first_name: studentName[0],
            last_name: studentName[1],
          },
        };
      });
      dispatch(slice.actions.getUserLessonsSuccess(configuredTimeArray));
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

/**
 *
 * @param id  Id of the user account of the student
 * @param start_date Lessons between this date, this is the first date
 * @param end_date Lessons between this date, this is the last date
 * @returns array of lessons and assings them to the state
 */
export function getLessonsForTeacher(
  id: string | number,
  start_date: string | undefined,
  end_date: string | undefined
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    // console.log('herer si the id for lessonsForStudent', id);
    const url =
      start_date && end_date
        ? `/api/lessons-teacher/${id}?start_date=${start_date}&end_date=${end_date}&status=scheduled`
        : `/api/lessons-teacher/${id}`;
    try {
      const response = await axios.get(url, { withCredentials: true });
      // console.log(response);
      const configuredTimeArray = response.data.data.map((lesson: ICalendarEvent) => {
        const teacherName = lesson.teacherName.split(' ');
        const studentName = lesson.studentName.split(' ');
        // console.log(lesson);
        return {
          ...lesson,
          from_time: new Date(`${lesson.from_time.toString()}Z`),
          to_time: new Date(`${lesson.to_time.toString()}Z`),
          teacher: {
            id: lesson.teacherId.code,
            first_name: teacherName[0],
            last_name: teacherName[1],
          },
          student: {
            id: lesson.studentId.code,
            first_name: studentName[0],
            last_name: studentName[1],
          },
        };
      });
      dispatch(slice.actions.getUserLessonsSuccess(configuredTimeArray));
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getLessonRecordings(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/lesson_replay/${id}`, { withCredentials: true });
      if (response.status === 200) {
        dispatch(slice.actions.getLessonRecodingsSuccess(response.data));
        return true;
      } else {
        throw new Error('Lesson not found');
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function updateLessonPlan(lessonPlan: Partial<ILessonPlan>) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoadingLessonPlan());
      // console.log(lessonPlan);
      const response = await axios.put(
        `/api/update_lesson_plan`,
        {
          ...lessonPlan,
        },
        { withCredentials: true }
      );

      if (response.status === 201) {
        dispatch(
          slice.actions.getLessonPlan({
            ...response.data,
            from_time: new Date(`${response.data.from_time}Z`),
            to_time: new Date(`${response.data.to_time}Z`),
            timeline: JSON.parse(response.data.timeline).timeline,
          })
        );
      } else {
        throw new Error('Invalid request');
      }

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function deleteLessonPlan(id: string | number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.delete(`/api/delete_lesson_plan/${id}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        dispatch(slice.actions.deleteLessonPlanSuccess(response.data));
      } else {
        throw new Error('Invalid request');
      }
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function generateLessonPlanExercises(id: string | number, extraRequirements: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoadingExercises());
      const response = await axios.post(
        `/api/plan_exercises`,
        {
          lesson_plan_id: id,
          extra_requirements: extraRequirements,
        },
        { withCredentials: true }
      );

      if (response.status === 201) {
        dispatch(slice.actions.getLessonPlanExercises(response.data));
      } else {
        throw new Error('Invalid request');
      }

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getLessonPlan(id: string | number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoadingLessonPlan());
      const response = await axios.get(`/api/lesson_plan/${id}`, { withCredentials: true });

      if (response.status === 200) {
        dispatch(
          slice.actions.getLessonPlan({
            ...response.data,
            from_time: new Date(`${response.data.from_time}Z`),
            to_time: new Date(`${response.data.to_time}Z`),
            timeline: JSON.parse(response.data.timeline).timeline,
          })
        );
      } else {
        throw new Error('Invalid request');
      }

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function createLessonPlan(
  lesson_id: string,
  brief: string,
  lesson_goal: string,
  grade: string,
  subject: string,
  level: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoadingLessonPlan());
      const response = await axios.post('/api/lesson_plan', {
        lesson_id: lesson_id,
        brief: brief,
        lesson_goal: lesson_goal,
        grade: grade,
        subject: subject,
        level: level,
      });
      if (response.status === 200) {
        dispatch(
          slice.actions.getLessonPlan({
            id: response.data.id,
            brief: response.data.brief,
            level: response.data.level,
            grade: response.data.grade,
            subject: response.data.subject,
            duration_in_minutes: response.data.duration_in_minutes,
            lesson_goal: response.data.lesson_goal,
            from_time: new Date(`${response.data.from_time}Z`),
            to_time: new Date(`${response.data.to_time}Z`),
            timeline: JSON.parse(response.data.timeline).timeline,
            exercises: response.data.exercises,
          })
        );
      } else {
        const errorMessage = response.data.message;
        throw Error(errorMessage);
      }
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getUnapprovedLessons(lessons: ILesson[]) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.getUnapprovedLessonsSuccess(lessons));
  };
}

export function updateLessonApproved(
  lesson: Partial<{
    id: string | number | null;
    approved: boolean | null;
  }>
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`api/set-lesson-approved`, {
        id: lesson?.id,
        approved: lesson?.approved,
      });
      dispatch(
        slice.actions.updateLessonApproved({
          id: response.data.id,
          approved: response.data.approved,
        })
      );
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
