import React from 'react';
import Latex from 'react-latex-next';

export function parseMarkdown(text: string): (JSX.Element | string)[] {
  const bold = /\*\*(.*?)\*\*/g;
  const italic = /\*(.*?)\*/g;
  const color = /\[color:(.*?)\](.*?)\[\/color\]/g;

  return text
    .split(bold)
    .map((part, i) => {
      if (i % 2) {
        return <strong key={`bold-${i}`}>{part}</strong>;
      }
      return part;
    })
    .flatMap((part) => {
      if (typeof part === 'string') {
        return part.split(italic).map((subPart, j) => {
          if (j % 2) {
            return <em key={`italic-${j}`}>{subPart}</em>;
          }
          return subPart;
        });
      }
      return [part];
    })
    .reduce<(JSX.Element | string)[]>((acc, part) => {
      if (typeof part === 'string') {
        const colorSegments = part.split(color);
        for (let k = 0; k < colorSegments.length; k++) {
          if (k % 3 === 1) {
            const colorValue = colorSegments[k];
            const textToColor = colorSegments[k + 1];
            if (textToColor) {
              acc.push(
                <span key={`color-${k}`} style={{ color: colorValue }}>
                  {textToColor}
                </span>
              );
            }
          } else if (k % 3 === 0) {
            acc.push(colorSegments[k]);
          }
        }
      } else {
        acc.push(part);
      }
      return acc;
    }, []);
}

interface LatexWithMarkdownProps {
  text: string;
}

export function LatexWithMarkdown({ text }: LatexWithMarkdownProps) {
  // split text into latex and non latex
  const splitLatex = text.split(/(\\\(.*?\\\))/gs);

  return (
    <>
      {splitLatex.map((segment, index) => {
        if (segment.startsWith('\\(') && segment.endsWith('\\)')) {
          // Render latex directly
          return <Latex key={`latex-${index}`}>{segment}</Latex>;
        } else {
          // handle Markdown and colors -- for non latex segments
          const markdownParts = parseMarkdown(segment);
          return <React.Fragment key={`md-${index}`}>{markdownParts}</React.Fragment>;
        }
      })}
    </>
  );
}
