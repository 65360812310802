// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import { getAnalytics } from 'firebase/analytics';
import { getMessaging, onMessage, getToken, isSupported } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();
// Register the service worker
export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        onMessage(messagingResolve, (payload) => {
          // console.log('On message: ', messaging, payload);
          try {
            // ...
            const data = payload.data;
            if (data) {
              const market = data['market_name'];
              const notificationTitle = data['title'] || 'TopTutors notification';
              const notificationOptions = {
                body: data.body || '',
                icon: '/favicon/apple-touch-icon.png',
                badge: '/favicon/apple-touch-icon.png',
                image: '/favicon/apple-touch-icon.png',
                timestamp: new Date().getTime(),
              };
              console.log('notification');
              // showNotification(notificationTitle, notificationOptions);
              if ('serviceWorker' in navigator) {
                navigator.serviceWorker.ready.then((registration) => {
                  console.log('TESTING workers');
                  registration.showNotification(notificationTitle, notificationOptions);
                });
                resolve(payload);
              }
            }
          } catch (e) {
            console.log(e);
          }
        });
      }
    })()
  );

export default app;
