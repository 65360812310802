import Iconify from 'src/components/iconify/Iconify';
import { Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// types
import { AuthUserType } from 'src/auth/types';
// ----------------------------------------------------------------------

const ICONS = {
  dashboard: <Iconify icon="ph:gauge" sx={{ width: 1, height: 1 }} />,
  profile: <Iconify icon="ph:address-book" sx={{ width: 1, height: 1 }} />,
  calendar: <Iconify icon="ph:calendar" sx={{ width: 1, height: 1 }} />,
  student: <Iconify icon="ph:student" sx={{ width: 1, height: 1 }} />,
  family: <Iconify icon="ph:users-three" sx={{ width: 1, height: 1 }} />,
  tutor: <Iconify icon="ph:graduation-cap" sx={{ width: 1, height: 1 }} />,
  lesson: <Iconify icon="ph:chalkboard-teacher" sx={{ width: 1, height: 1 }} />,
  invoice: <Iconify icon="ph:subtitles" sx={{ width: 1, height: 1 }} />,
  payslip: <Iconify icon="ph:vault" sx={{ width: 1, height: 1 }} />,
  balances: <Iconify icon="ph:wallet" sx={{ width: 1, height: 1 }} />,
  transaction: <Iconify icon="ph:money" sx={{ width: 1, height: 1 }} />,
  leaderboard: <Iconify icon="ic:outline-leaderboard" sx={{ width: 1, height: 1 }} />,
  courses: <Iconify icon="ph:book-open" sx={{ width: 1, height: 1 }} />,
  tutorMap: <Iconify icon="ph:map-pin-bold" sx={{ width: 1, height: 1 }} />,
  earnings: <Iconify icon="ph:currency-circle-dollar" sx={{ width: 1, height: 1 }} />,
  rapport: <Iconify icon="ph:address-book" sx={{ width: 1, height: 1 }} />,
  email: <Iconify icon="ph:archive-light" sx={{ width: 1, height: 1 }} />,
  membership: <Iconify icon="ph:user-list" sx={{ width: 1, height: 1 }} />,
  chat: <Iconify icon="ph:chats-circle" sx={{ width: 1, height: 1 }} />,
  request: <Iconify icon="ph:arrow-bend-left-down" sx={{ width: 1, height: 1 }} />,
  notes: <Iconify icon="ph:note" sx={{ width: 1, height: 1 }} />,
  ai: <Box component="img" src={'/assets/ai_icon.svg'} />,
};

const navConfig = (user: AuthUserType, translate: any) => [
  // GENERAL

  // ----------------------------------------------------------------------
  !user?.freemium_signup
    ? {
        subheader: '',
        items: [
          // All
          {
            title: translate('overview.title'),
            path: PATH_DASHBOARD.dashboard,
            icon: ICONS.dashboard,
            roles: ['admin', 'customer', 'teacher', 'student'],
            markets: ['1', '2'],
          },
          {
            title: translate('calender.calender'),
            path: PATH_DASHBOARD.calendar,
            icon: ICONS.calendar,
            roles: ['admin', 'customer', 'teacher', 'student'],
            markets: ['1', '2'],
          },
          {
            title: translate('personalNotes.notes'),
            path: '/document/home',
            icon: ICONS.notes,
            roles: ['admin', 'customer', 'teacher', 'student'],
            markets: ['1', '2'],
            isNew: true,
          },
          // {
          //   title: translate('chat.chatTitle'),
          //   path: PATH_DASHBOARD.chat.root,
          //   icon: ICONS.chat,
          //   roles: ['admin', 'customer', 'teacher', 'student'],
          //   markets: ['1', '2'],
          // },
          user?.customer_dict?.has_membership && user.customer_dict.monthly_credits_ordered !== 0
            ? {
                title: translate('customer.myMembership'),
                path: PATH_DASHBOARD.membership.view(user?.customer_dict?.id),
                icon: ICONS.membership,
                roles: ['customer'],
                markets: ['1', '2'],
              }
            : user?.customer_dict?.monthly_credits_ordered === 0 &&
              user?.customer_dict?.monthly_credits_left === 0
            ? {
                title: translate('customer.buyMembership'),
                path: `/membership/order/${user?.customer_dict?.id}`,
                icon: ICONS.membership,
                roles: ['customer'],
                markets: ['1', '2'],
              }
            : {
                title: translate('customer.balance'),
                path: PATH_DASHBOARD.membership.view(user?.customer_dict?.id),
                icon: ICONS.membership,
                roles: ['customer'],
                markets: ['1', '2'],
              },
          // Admin Only
          {
            title: translate('customer.customer'),
            path: PATH_DASHBOARD.family.root,
            icon: ICONS.family,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('customer.students'),
            path: PATH_DASHBOARD.student.root,
            icon: ICONS.student,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tutor.tutor'),
            path: PATH_DASHBOARD.tutor.root,
            icon: ICONS.tutor,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.lessons'),
            path: PATH_DASHBOARD.lesson.root,
            icon: ICONS.lesson,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.order'),
            path: PATH_DASHBOARD.invoice.root,
            icon: ICONS.invoice,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.paychecks'),
            path: PATH_DASHBOARD.payslip.root,
            icon: ICONS.payslip,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.balancer'),
            path: PATH_DASHBOARD.balances.root,
            icon: ICONS.balances,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.transactions'),
            path: PATH_DASHBOARD.transaction.root,
            icon: ICONS.transaction,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.tutorMap'),
            path: 'https://toptutors.com/admin/tutormapview/',
            icon: ICONS.tutorMap,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.lastLesson'),
            path: PATH_DASHBOARD.report.root,
            icon: ICONS.rapport,
            roles: ['admin'],
            markets: ['1', '2'],
          },
          {
            title: translate('tabs.email'),
            path: PATH_DASHBOARD.email.root,
            icon: ICONS.email,
            roles: ['admin'],
            markets: ['1', '2'],
          },

          // Customer
          {
            title: translate('customer.account'),
            path: PATH_DASHBOARD.family.profile(user?.customer_dict?.id),
            icon: ICONS.profile,
            roles: ['customer'],
            markets: ['1', '2'],
          },
          {
            title: translate('customer.tutor'),
            path: PATH_DASHBOARD.family.teacherList,
            icon: ICONS.tutor,
            roles: ['customer'],
            markets: ['1', '2'],
          },
          {
            title: translate('tutor.myStudents'),
            path: PATH_DASHBOARD.family.studentList,
            icon: ICONS.student,
            roles: ['customer'],
          },
          // Student
          {
            title: translate('customer.account'),
            path: PATH_DASHBOARD.student.profile(user?.student_dict?.id),
            icon: ICONS.profile,
            roles: ['student'],
            markets: ['1', '2'],
          },
          {
            title: translate('customer.tutor'),
            path: PATH_DASHBOARD.student.teacherList,
            icon: ICONS.tutor,
            roles: ['student'],
            markets: ['1', '2'],
          },
          // {
          //   title: 'Leaderboard',
          //   path: PATH_DASHBOARD.leaderboard.root,
          //   icon: ICONS.leaderboard,
          //   roles: ['admin',],
          //   markets: ['1', '2'],
          // },
          // Tutor
          {
            title: translate('customer.account'),
            path: PATH_DASHBOARD.tutor.profile(user?.teacher_dict?.id),
            icon: ICONS.profile,
            roles: ['teacher'],
            markets: ['1', '2'],
          },
          {
            title: translate('earnings.title'),
            path: PATH_DASHBOARD.tutor.earnings(user?.teacher_dict?.id),
            icon: ICONS.earnings,
            roles: ['teacher'],
            markets: ['1', '2'],
          },
          {
            title: translate('tutor.myStudents'),
            path: PATH_DASHBOARD.tutor.studentList,
            icon: ICONS.student,
            roles: ['teacher'],
            markets: ['1', '2'],
          },
          {
            title: translate('tutor.requests'),
            path: PATH_DASHBOARD.lessonRequest.viewTeacherRequests(user?.teacher_dict?.id),
            icon: ICONS.request,
            roles: ['teacher'],
            markets: ['1', '2'],
          },
          {
            title: translate('invite.inviteFriends'),
            path: PATH_DASHBOARD.refer.profile(user?.uid),
            icon: ICONS.transaction,
            roles: ['teacher', 'customer', 'student'],
            markets: ['1', '2'],
          },
          {
            title: translate('course.coursesList'),
            path: PATH_DASHBOARD.tutor.course,
            icon: ICONS.courses,
            roles: ['teacher'],
            markets: ['1'],
          },
        ],
      }
    : {
        subheader: '',
        items: [
          {
            title: translate('customer.account'),
            path: PATH_DASHBOARD.family.profile(user?.customer_dict?.id),
            icon: ICONS.profile,
            roles: ['customer'],
            markets: ['1', '2'],
            freemium: true,
          },
          {
            title: translate('personalNotes.notes'),
            path: '/document/home',
            icon: ICONS.notes,
            roles: ['admin', 'customer', 'teacher', 'student'],
            markets: ['1', '2'],
            isNew: true,
            freemium: true,
          },
        ],
      },
];

export default navConfig;
