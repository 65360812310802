// @mui
import { List, Stack, Box } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';

import NavList from './NavList';
import AIButton from '../../../components/aibutton';
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
import { useLocation } from 'react-router-dom';
import { SingleHoursOrder } from '../../../sections/@dashboard/membership';

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  data,
  sx,
  isCollapsed = false,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();
  const { pathname } = useLocation();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: isCollapsed ? 1 : 2 }}>
            {group.subheader && !isCollapsed && (
              <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
                isCollapsed={isCollapsed}
              />
            ))}

            {!isCollapsed && pathname !== '/ai' && (
              <Box sx={{ mt: 2 }}>
                <SingleHoursOrder />
              </Box>
            )}

            {!isCollapsed && (
              <RoleBasedGuard
                freemium={true}
                roles={['admin', 'customer', 'student', 'teacher']}
                markets={['1', '2']}
              >
                <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
                  <AIButton
                    label={`${translate('ai.title')}`}
                    link="/ai"
                    hueValue={0}
                    includeLabel={!['/ai', '/document'].includes(pathname)}
                  />
                </Box>
              </RoleBasedGuard>
            )}
          </List>
        );
      })}
    </Stack>
  );
}
