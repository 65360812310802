import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import {
  updateFoldersAfterNoteCreation,
  updateFoldersAfterNoteUpdate,
  updateFoldersAfterNoteDeletion,
} from './folders';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { INote, INoteState } from 'src/@types/note';

const initialState: INoteState = {
  isLoading: false,
  error: null,
  notes: [],
  note: null,
  improvements: [],
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // SET note to null
    setNoteNull(state) {
      state.note = null;
    },
    // STOPS LOADING
    stopLoading(state) {
      state.isLoading = false;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // LOADS POTENTIAL IMPROVEMENTS
    generateImprovementsSuccess(state, action) {
      state.error = null;
      state.improvements = [...action.payload];
    },
    // POST Note to database
    postNoteSuccess(state, action) {
      state.isLoading = false;
      state.note = action.payload;
      state.error = null;
      state.notes = [action.payload, ...state.notes];
    },
    // GET All Notes
    getNotesSuccess(state, action) {
      state.isLoading = false;
      // Usually uses ...state.notes, ...action.payloard
      // Creates a weird double load, so removed ...state.notes for now
      state.notes = action.payload;
    },
    // GET Specific Note by ID
    getNoteSuccess(state, action) {
      state.isLoading = false;
      state.note = action.payload.data;
      state.error = null;
    },
    // PUT Updates onto note via ID
    getUpdateSuccess(state, action) {
      state.note = action.payload;
      state.notes = state.notes.map((note: INote) => {
        if (parseInt(note.id.toString() || '') === parseInt(action.payload.id)) {
          return action.payload;
        }
        return note;
      });
    },
    // DELETE Note via ID
    deleteNoteSuccess(state, action) {
      // Combine notes from both state and folder
      const allNotes = [...state.notes.filter((v) => v.id !== action.payload.old_note_id)];
      state.isLoading = false;
      state.error = null;
      state.notes = allNotes;
      state.note = action.payload.data;
    },
    moveNoteToFolderSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      // Update notes array with the moved note
      state.notes = state.notes.map((note: INote) => {
        if (note.id === action.payload.id) {
          return action.payload;
        }
        return note;
      });
    },
    setAiLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
console.log(slice);
export const { startLoading, stopLoading, deleteNoteSuccess, setAiLoading } = slice.actions;
export default slice.reducer;
export function getJwtNoteToken() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/api/generate_jwt_token_tiptap`, {
        withCredentials: true,
      });
      console.log(response);
      if (response.status === 403) {
        throw new Error('Access denied');
      } else {
        dispatch(slice.actions.stopLoading());
        localStorage.setItem('aiTipTapToken', response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function startNoteEditor(user_id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.stopLoading());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/start_note_editor`,
        { user_id: user_id },
        {
          withCredentials: true,
        }
      );
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Note not found');
      } else {
        console.log(response);
        dispatch(slice.actions.getNoteSuccess(response));
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getNotes(user_id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_notes/${user_id}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getNote(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_note/${id}`, {
        withCredentials: true,
      });
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        console.log(response);
        dispatch(slice.actions.getNoteSuccess(response));
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createNote(user_id: number, folderId?: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/create_note`, {
        user_id: user_id,
        folder_id: folderId,
        name: '',
        is_public: false,
        output_field: ``,
      });
      // console.log(response);
      if (response.data.folder_id) {
        dispatch(slice.actions.stopLoading());
        dispatch(updateFoldersAfterNoteCreation(response.data));
        dispatch(slice.actions.getNoteSuccess(response));
      } else {
        dispatch(slice.actions.postNoteSuccess(response.data));
      }
      return response.data;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function aiImproveContent(htmlContent: string, goalImprovement: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/improve_note`, {
        html_content: htmlContent,
        goal_improvement: goalImprovement,
      });
      console.log(response);
      dispatch(slice.actions.generateImprovementsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function saveNote(id: string | null, name: string, output_field: string) {
  return async (dispatch: Dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/update_note`, {
        id: id,
        name: name,
        output_field: output_field,
      });
      // console.log(response);
      if (response.data.folder_id) {
        dispatch(updateFoldersAfterNoteUpdate(response.data));
      } else {
        dispatch(slice.actions.getUpdateSuccess(response.data));
      }
      dispatch(slice.actions.stopLoading());

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function publicizeNote(id: string | null) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/publicize_note`, {
        id: id,
        is_public: true,
      });
      // console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function deleteNote(note: INote) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/delete_note/${note.id}`, {});
      // console.log(response);
      if (note.folder_id) {
        dispatch(updateFoldersAfterNoteDeletion(note.id));
      }
      dispatch(slice.actions.deleteNoteSuccess({ data: response.data, old_note_id: note.id }));

      dispatch(slice.actions.stopLoading());
      return response.data;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function exportNote(htmlContent: string, fileType: string, fileName: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const contentType =
        fileType === 'pdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

      const response = await axios.post(
        `/api/export_note`,
        {
          htmlContent: `
            <!DOCTYPE html>
            <html>
              <head>
                <meta charset="utf-8">
                <title>${fileName}</title>
              </head>
              <body>
                ${htmlContent}
              </body>
            </html>
          `,
          fileType: fileType,
        },
        {
          responseType: 'arraybuffer', // Changed from 'blob' to 'arraybuffer'
          headers: {
            'Content-Type': 'application/json',
            withCredentials: true,
            Accept: contentType,
          },
        }
      );

      // Create blob from array buffer with proper type
      const blob = new Blob([response.data], { type: contentType });
      const fileExtension = fileType === 'pdf' ? '.pdf' : '.docx';
      const safeFileName = fileName.trim().replace(/\.$/, '') || 'toptutors_export';
      const fullFileName = `${safeFileName}${fileExtension}`;

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fullFileName;

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(slice.actions.stopLoading());
      return true;
    } catch (error) {
      console.error('Export error:', error);
      const errorMessage = error?.message || 'An error occurred during export';
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createAINote(
  note_id: number,
  user_id: number | null,
  subject: string,
  classLevel: string,
  topic: string,
  files: File[],
  style: string,
  onStreamData: (data: string) => void,
  onSetTitle: (title: string) => void
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    return new Promise((resolve, reject) => {
      // Build URL with query parameters
      const baseUrl = process.env.REACT_APP_HOST_API_KEY || '';
      const url =
        `${baseUrl}/api/create_ai_note?` +
        `note_id=${encodeURIComponent(String(note_id))}` +
        `&subject=${encodeURIComponent(subject)}` +
        `&classLevel=${encodeURIComponent(classLevel)}` +
        `&topic=${encodeURIComponent(topic)}` +
        `&style=${encodeURIComponent(style)}` +
        (user_id ? `&user_id=${encodeURIComponent(String(user_id))}` : '');

      const eventSource = new EventSourcePolyfill(url, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
        },
      });

      let noteContent = '';
      let newTitle = '';
      const timeoutDuration = 100000; // 100 seconds
      let timeoutId: ReturnType<typeof setTimeout> | null = null;

      const timeoutHandler = () => {
        console.log('EventSource request timed out');
        eventSource.close();
        dispatch(slice.actions.hasError('Request timed out'));
        reject(new Error('Request timed out'));
      };

      const clearEventSourceTimeout = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
      };

      eventSource.onmessage = (event) => {
        clearEventSourceTimeout();

        if (event.data === 'ENDSTREAM') {
          dispatch(slice.actions.stopLoading());
          eventSource.close();
          resolve(noteContent);
        } else if (event.data === 'PAUSE') {
          noteContent += '\n';
        } else if (event.data.includes('initial_data')) {
          const cleanData = event.data.replace(/data:\s*$/, '');
          try {
            const parsedData = JSON.parse(cleanData);
            newTitle = parsedData.data;
            if (onSetTitle) {
              onSetTitle(newTitle);
            }
          } catch (error) {
            console.error('Error parsing initial data:', error);
            console.log('Raw data received:', event.data);
          }
        } else {
          noteContent += event.data;
          if (onStreamData) {
            onStreamData(noteContent);
          }
          timeoutId = setTimeout(timeoutHandler, timeoutDuration);
        }
      };

      eventSource.onerror = (error) => {
        dispatch(slice.actions.stopLoading());
        console.error('EventSource encountered an error:', error);
        clearEventSourceTimeout();
        eventSource.close();
        dispatch(slice.actions.hasError('Error generating note content'));
        reject(new Error('Error with message'));
      };

      // Start initial timeout
      timeoutId = setTimeout(timeoutHandler, timeoutDuration);
    });
  };
}

export function createAISummary(
  user_id: number | null,
  files: File[],
  style: string,
  onStreamData: (data: string) => void,
  onSetTitle: (title: string) => void
) {
  return async (dispatch: Dispatch) => {
    try {
      const formData = new FormData();
      if (user_id) {
        formData.append('user_id', user_id.toString());
      }
      for (const file of files) {
        formData.append('file', file);
      }
      formData.append('style', style);

      dispatch(slice.actions.startLoading());

      // Upload files first
      await axios.post('/api/upload_files_for_summary', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      // Then establish SSE connection with file references
      const baseUrl = process.env.REACT_APP_HOST_API_KEY || '';
      const params = new URLSearchParams({
        user_id: user_id?.toString() || '',
        style,
      });

      let retryCount = 0;
      const MAX_RETRIES = 3;

      const createEventSource = () => {
        const eventSource = new EventSourcePolyfill(`${baseUrl}/api/create_ai_summary?${params}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          withCredentials: true,
          heartbeatTimeout: 300000, // 5 minutes
        });
        return eventSource;
      };

      let summaryContent = '';
      let newTitle = '';
      const timeoutDuration = 300000; // 5 minutes
      let timeoutId: ReturnType<typeof setTimeout> | null = null;
      let eventSource = createEventSource();

      return new Promise((resolve, reject) => {
        const timeoutHandler = () => {
          console.log('EventSource request timed out');
          eventSource.close();

          if (retryCount < MAX_RETRIES) {
            retryCount++;
            console.log(`Retrying connection (${retryCount}/${MAX_RETRIES})`);
            eventSource = createEventSource();
            setupEventHandlers();
          } else {
            dispatch(slice.actions.hasError('Request timed out after retries'));
            reject(new Error('Request timed out after retries'));
          }
        };

        const clearEventSourceTimeout = () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
          }
        };

        const setupEventHandlers = () => {
          eventSource.onmessage = (event) => {
            clearEventSourceTimeout();
            retryCount = 0; // Reset retry count on successful message

            if (event.data === 'ENDSTREAM') {
              dispatch(slice.actions.stopLoading());
              eventSource.close();
              resolve(summaryContent);
            } else if (event.data === 'PAUSE') {
              summaryContent += '\n';
            } else if (event.data.includes('initial_data')) {
              try {
                const parsedData = JSON.parse(event.data);
                newTitle = parsedData.data;
                if (onSetTitle) {
                  onSetTitle(newTitle);
                }
              } catch (error) {
                console.error('Error parsing initial data:', error);
              }
            } else {
              summaryContent += event.data;
              if (onStreamData) {
                dispatch(slice.actions.stopLoading());
                onStreamData(summaryContent);
              }
            }

            timeoutId = setTimeout(timeoutHandler, timeoutDuration);
          };

          eventSource.onerror = (error) => {
            console.error('EventSource encountered an error:', error);
            clearEventSourceTimeout();
            eventSource.close();

            if (retryCount < MAX_RETRIES) {
              retryCount++;
              console.log(`Retrying connection (${retryCount}/${MAX_RETRIES})`);
              setTimeout(() => {
                eventSource = createEventSource();
                setupEventHandlers();
              }, 1000 * retryCount); // Exponential backoff
            } else {
              dispatch(slice.actions.hasError('Error generating summary after retries'));
              reject(new Error('Error with EventSource after retries'));
            }
          };
        };

        setupEventHandlers();
        timeoutId = setTimeout(timeoutHandler, timeoutDuration);
      });
    } catch (error) {
      console.error(error);
      let errorMessage = '';
      if (error?.errors?.json?._schema) {
        errorMessage = error.errors.json._schema[0];
      } else if (error?.messages?.json?._schema) {
        errorMessage = error.messages.json._schema[0];
      } else {
        errorMessage = error?.message || 'An error occurred';
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createAIEssay(
  user_id: number | null,
  subject: string,
  classLevel: string,
  topic: string,
  details: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const formData = new FormData();
      if (user_id) {
        formData.append('user_id', user_id.toString());
      }
      formData.append('subject', subject);
      formData.append('classLevel', classLevel);
      formData.append('topic', topic);
      formData.append('details', details);

      const response = await axios.post('/api/create_ai_essay', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        dispatch(slice.actions.postNoteSuccess(response.data));
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createDocument(note_id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/create_document`, {
        note_id: note_id,
        // needs to somehow generate token
        storage_string: 'temp string',
      });
      console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function moveNoteToFolder({ noteId, folderId }: { noteId: number; folderId: number }) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(noteId, folderId);
      const response = await axios.put(
        `/api/move_note_to_folder/${noteId}`,
        { folder_id: folderId },
        { withCredentials: true }
      );

      dispatch(slice.actions.moveNoteToFolderSuccess(response.data));

      // Optionally refresh folders if needed
      if (response.data.folder_id) {
        dispatch(updateFoldersAfterNoteUpdate(response.data));
      }

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
