import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';

// @mui
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Box, Card, Grid, Button, Link, Typography, Stack, Checkbox } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useTheme, alpha } from '@mui/material/styles';
// utils
import { fCurrency, fPercent } from '../../../utils/formatNumber';
// select options
import { getMemberships, discounts, getMonths } from '../../../assets/data/memberships';
import { currency, getCurrencyLabel } from '../../../assets/data/currency';
// routes
// @types
import { IInvoice } from '../../../@types/invoice';
import { ICustomerPagination, ICustomer } from '../../../@types/customer';

import { useLocales } from 'src/locales';

// components
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';
import { Guarantee } from 'src/sections/@dashboard/membership';

import FormProvider, {
  RHFAutocomplete,
  RHFSelect,
  RHFCheckbox,
  RHFTextField,
} from '../../../components/hook-form';
import { ICharge } from '../../../@types/charges';
import { IPricing } from '../../../@types/pricing';
import { useAuthContext } from '../../../auth/useAuthContext';
import Iconify from '../../../components/iconify/Iconify';
// redux

// ----------------------------------------------------------------------
interface CustomerIdObject {
  label: string;
  id: string;
}

interface FormValuesProps extends Omit<IInvoice, 'avatarUrl' | 'customer_id'> {
  avatarUrl: CustomFile | string | null;
  plan_selected: string;
  installments: number;
  email: string;
  customer_id: CustomerIdObject;
  additional_discount: number;
}

type Props = {
  customer?: ICustomer | null;
  invoice?: IInvoice | null;
  admin: boolean;
  superAdmin: boolean;
  customers?: ICustomerPagination;
  pricings?: IPricing[];
  handleCreateUpdate: (form: IInvoice, additionalCharges: ICharge[]) => void;
};

export default function CreateMembershipForm({
  customer,
  invoice,
  customers,
  pricings,
  admin,
  superAdmin,
  handleCreateUpdate,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [additionalCharges, setAdditionalCharges] = useState<ICharge[]>([]);
  const { user } = useAuthContext();

  const { translate } = useLocales();

  const theme = useTheme();
  // const dispatch = useDispatch();

  const memberships = useMemo(() => getMemberships(theme, translate), [theme, translate]);

  const months = getMonths(translate);
  const defaultValuesIndependent = useMemo(
    () => ({
      id: invoice?.id || '',
      status: invoice?.status || '',
      name: invoice?.name || `${customer?.first_name} ${customer?.last_name}` || '',
      email: '',
      customer_id: {
        label: invoice?.customer_id || customer?.id || '',
        id: invoice?.customer_id || customer?.id || '',
      },
      email_sent: invoice?.email_sent || true,
      churn_penalty: invoice?.churn_penalty || false,
      discount: invoice?.discount ? invoice?.discount : '',
      stripe_id: invoice?.stripe_invoice_id || '',
      expiration_date:
        invoice?.expiration_date || new Date(new Date().getFullYear(), new Date().getMonth() + 1),
      extra_student: invoice?.extra_student || 0,
      plan_selected: invoice?.type_order?.split('-')[0] || '6',
      amount: invoice?.total_price || 0,
      total_hours:
        (invoice?.total_hours && invoice?.total_hours / invoice?.installments) ||
        parseInt(invoice?.type_order?.split('-')[0] || '6') * (invoice?.installments || 6) ||
        0,
      installments: invoice?.installments || 12,
      currency: invoice?.currency || user?.market?.currency || customer?.currency || 'DKK',
      pricing_id: invoice?.pricing_id || '',
      additional_discount: invoice?.additional_discount || 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoice]
  );
  console.log(invoice?.additional_discount);
  const methods = useForm<FormValuesProps>({
    defaultValues: defaultValuesIndependent,
  });
  // console.log(defaultValuesIndependent);
  const { reset, watch, setValue, handleSubmit } = methods;

  const values = watch();
  // console.log(values?.expiration_date);
  const isEdit = invoice?.status === 'pending' || !invoice ? false : true;
  const [nonStandardMembership, setNonStandardMembership] = useState(false);
  // console.log(invoice);
  useEffect(() => {
    // console.log(invoice);
    if (invoice) {
      // console.log('Invoice run');
      setPrice(invoice.unit_price);
      reset(defaultValuesIndependent);
    }
    if (customer) {
      setPrice(299);
      reset(defaultValuesIndependent);
    }
  }, [invoice]);
  console.log(values?.additional_discount);

  const price_compare =
    pricings?.find((value) => value.infliction_point === 0)?.price_per_hour || 0;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);

  // State for acceptTerms with default value based on admin
  const [acceaptTerms, setAcceptTerms] = useState(admin);
  // console.log(values.discount);
  const [discountvisual, setDiscountVisual] = useState(1);
  // console.log(discountvisual);
  const onSubmit = async (data: FormValuesProps) => {
    // console.log(data);
    // console.log(data.customer_id.id);
    try {
      const hours = data.installments * parseInt(data.plan_selected);
      const installments = data.installments;

      // console.log(discount);
      // if (referral) {
      //   discount = referral.code;
      // }
      // console.log(values?.plan_selected);
      if (acceaptTerms) {
        handleCreateUpdate(
          {
            ...data,
            customer_id: data.customer_id.id,
            email: email,
            name: name,
            total_hours: Number(hours),
            installments: installments,
            membership: true,
            discount: data.discount === '' ? undefined : data.discount,
            stripe_invoice_id: `${data.plan_selected}-months-${installments}`,
            pricing_id: data.pricing_id,
          },
          additionalCharges
        );
      } else {
        enqueueSnackbar(`${translate('snackBar.acceptTCs')}`, { variant: 'error' });
      }

      reset();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`${translate('snackBar.orderCreatingError')}`, { variant: 'success' });
    }
  };
  // Effect for managing plan selection changes.
  // And modify the main effect
  useEffect(() => {
    if (!values?.plan_selected) return;

    const plan = memberships.find((v) => v.code === values.plan_selected);
    if (!plan) return;

    setValue('total_hours', Number(plan.code) || 6);
    const newPrice = pricings?.find((v) => v.infliction_point === values.installments * plan.value);

    setNonStandardMembership(!plan.standard);

    if (newPrice) {
      setPrice(newPrice.price_per_hour);
      setDiscountVisual(newPrice.price_per_hour / price_compare);
      setValue('pricing_id', newPrice.id.toString());
    }
  }, [values.plan_selected, values.installments, pricings, setValue, price_compare]);
  // useEffect(() => {
  //   setValue(
  //     'discount',
  //     values.discount ? (Math.round(100 - parseInt(values.discount)) / 100).toString() : undefined
  //   );
  // }, []);

  console.log(values);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} sx={{ mb: 10 }}>
        <Grid item xs={12} md={7}>
          <Card
            sx={{
              p: 2,
              [theme.breakpoints.up('md')]: {
                borderRadius: '20px 0px 0px 20px',
              },
            }}
          >
            <Box rowGap={2} columnGap={2} display="grid">
              <Grid item xs={12}>
                <Typography sx={{ mt: '20px', textAlign: 'center' }} variant="h3" color={'primary'}>
                  {`${translate('memberships.viewMembership')}`}
                </Typography>
                <Typography sx={{ m: '10px', textAlign: 'center' }} variant="h5">
                  {`${translate('memberships.selectMembership')}`}
                </Typography>
                {/* Plan */}
                <Stack direction="row" spacing={2} paddingLeft={5} paddingRight={5} paddingTop={3}>
                  {memberships.map((value) =>
                    nonStandardMembership !== value.standard ? (
                      <div
                        style={{ width: '100%' }}
                        onClick={() => {
                          setValue('plan_selected', value.code);
                        }}
                        key={value.code}
                      >
                        <Card
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                            border:
                              value.code === values.plan_selected
                                ? `2px solid ${theme.palette.primary.main}`
                                : 'none',
                            transform:
                              value.code === values.plan_selected ? 'scale(1.075)' : 'scale(1.0)',
                          }}
                        >
                          <Stack
                            direction="column"
                            style={{
                              padding: 20,
                              textAlign: 'center',
                              minHeight: 300,
                            }}
                            justifyContent="space-between"
                          >
                            {value.attentionText ? (
                              <Typography
                                variant="body2"
                                style={{
                                  color: value.attentionTextColor,
                                  backgroundColor: value.attentionBackgroundColor,
                                  borderRadius: 10,
                                }}
                              >
                                {value.attentionText}
                              </Typography>
                            ) : (
                              <Typography style={{ visibility: 'hidden' }}>hidden</Typography>
                            )}
                            <div style={{ paddingTop: 20, paddingBottom: 30 }}>
                              {value.icon && (
                                <Iconify
                                  icon={value.icon}
                                  color={theme.palette.error.main}
                                  style={{ width: '40px', height: '40px', margin: 'auto' }}
                                />
                              )}
                              <Typography variant="h5">{value.title}</Typography>
                              <Typography
                                variant="caption"
                                color={alpha(theme.palette.common.black, 0.75)}
                              >
                                {value.description}
                              </Typography>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="h6" style={{ marginRight: '5px' }}>
                                {value.label}
                              </Typography>
                              <Typography
                                variant="caption"
                                color={alpha(theme.palette.common.black, 0.75)}
                              >
                                {`${translate('memberships.perWeek')}`}
                              </Typography>
                            </div>
                            <Typography
                              variant="caption"
                              fontSize={'0.8rem'}
                              mt={1}
                              color={alpha(theme.palette.common.black, 0.75)}
                            >
                              {value.value} {`${translate('memberships.every4Weeks')}`}
                            </Typography>
                          </Stack>
                        </Card>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </Stack>

                {/* Duration */}
                <Typography variant="h5" sx={{ mt: '40px', textAlign: 'center' }}>
                  {`${translate('memberships.selectDuration')}`}
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  paddingLeft={5}
                  paddingRight={5}
                  paddingTop={3}
                  sx={{ width: '100%' }}
                >
                  {months.map((value) => (
                    <div
                      style={{ width: '100%' }}
                      key={value.code}
                      onClick={() => {
                        setValue('installments', parseInt(value.code));
                      }}
                    >
                      <Card
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%',
                          border:
                            value.code === String(values.installments)
                              ? `2px solid ${theme.palette.primary.main}`
                              : 'none',
                          transform:
                            value.code === String(values.installments)
                              ? 'scale(1.075)'
                              : 'scale(1.0)',
                        }}
                      >
                        <Stack
                          direction="column"
                          style={{
                            padding: 20,
                            textAlign: 'center',
                            // minHeight: 250,
                          }}
                          justifyContent="space-between"
                        >
                          <div
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="h6">{value.label}</Typography>
                          </div>
                        </Stack>
                      </Card>
                    </div>
                  ))}
                </Stack>
              </Grid>

              {admin && (
                <>
                  {customers && (
                    <Grid item xs={12}>
                      <RHFAutocomplete
                        disabled={isEdit}
                        required
                        autoComplete
                        autoHighlight
                        autoSelect
                        label="Kunde"
                        name="customer_id"
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={customers?.data.map((value: ICustomer) => ({
                          label: `${value.first_name} ${value.last_name} (${value.id})`,
                          id: value.id,
                        }))}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <RHFTextField
                      disabled={isEdit}
                      label="Antal ekstra elever"
                      name="extra_student"
                      type="number"
                    />
                  </Grid>
                  {/* Discount */}
                  <Grid item xs={12}>
                    <RHFSelect disabled={isEdit} native label="Rabat" name="discount">
                      <option />
                      {discounts.map((value) => (
                        <option label={value.label} value={value.value} key={value.code}>
                          {value.label}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>

                  <Grid item xs={12}>
                    <Tooltip
                      title={`Discounts allowed${
                        user?.market.id === '1' ? '500kr,1000kr,90kr' : '50 eur,100 eur,9.99eur'
                      }`}
                    >
                      <RHFTextField
                        disabled={isEdit}
                        label={`Additional discount(supported curently ${
                          user?.market.id === '1' ? '500kr,1000kr,90kr' : '50 eur,100 eur,9.99eur'
                        })`}
                        name="additional_discount"
                        type="number"
                      />
                    </Tooltip>
                  </Grid>
                </>
              )}

              {superAdmin && (
                <Grid item xs={12}>
                  <RHFSelect disabled={isEdit} native label="Currency" name="currency">
                    {currency.map((value) => (
                      <option label={value.label} value={value.value} key={value.code}>
                        {value.label}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid>
              )}

              {admin && (
                <Grid item xs={12} mt={2}>
                  <RHFCheckbox label="Send email" defaultChecked name="email_sent" />
                </Grid>
              )}

              {admin && (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    onClick={() => setNonStandardMembership(!nonStandardMembership)}
                  >
                    {nonStandardMembership
                      ? 'View regular memberships'
                      : 'Other non-standard memberships'}
                  </Button>
                </Grid>
              )}
            </Box>
            <Commitment market_id={invoice?.market_id} />
          </Card>
        </Grid>
        <Grid style={{ paddingLeft: '0px' }} item xs={12} md={5}>
          <Card
            sx={{
              p: 2,
              backgroundColor: theme.palette.background.neutral,
              height: '100%',
              [theme.breakpoints.up('md')]: { borderRadius: '0px 20px 20px 0px' },
            }}
          >
            <Box rowGap={3} columnGap={2} display="grid">
              <Grid container direction="row" justifyContent="space-evenly" spacing={1}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: '10px' }} variant="h5">
                    {`${translate('memberships.orderOverview')}`}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography color={alpha(theme.palette.common.black, 0.75)}>
                    {`${translate('memberships.hours')}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={theme.palette.common.black}>{values.total_hours}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography color={alpha(theme.palette.common.black, 0.75)}>
                    {discountvisual !== 1
                      ? `${translate('memberships.normalPrice')}`
                      : `${translate('memberships.yourPrice')}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  color={theme.palette.common.black}
                  xs={4}
                  sx={{ textDecoration: discountvisual !== 1 ? 'line-through' : 'none' }}
                >
                  {`
                    ${fCurrency(price_compare + values?.extra_student * 50 || 0)} 
                    ${getCurrencyLabel(values.currency)}
                  `}
                </Grid>

                {discountvisual !== 1 && (
                  <>
                    <Grid item xs={8}>
                      <Typography color={alpha(theme.palette.common.black, 0.75)}>
                        {`${translate('memberships.yourPrice')}`}
                      </Typography>
                    </Grid>{' '}
                    <Grid item xs={4} color={theme.palette.common.black}>
                      {`${fCurrency(
                        (price_compare + values?.extra_student * 50 || 0) * discountvisual
                      )} ${getCurrencyLabel(values.currency)}`}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <hr />
                </Grid>

                {discountvisual !== 1 && (
                  <>
                    <Grid item xs={8}>
                      <Typography sx={{ color: theme.palette.success.main }}>
                        {`${translate('memberships.discount')}`} (
                        {fPercent((1 - discountvisual) * 100, false)})
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5" sx={{ color: theme.palette.success.main }}>
                        {`${fCurrency(
                          -(
                            price_compare +
                            values?.extra_student * (50 || 0) -
                            (price_compare + values?.extra_student * (50 || 0)) * discountvisual
                          ) * values?.total_hours
                        )} ${getCurrencyLabel(values.currency)}`}
                      </Typography>
                    </Grid>
                    {/* {values.discount !== '' && !referral && (
                      <>
                        <Grid item xs={8}>
                          <Typography sx={{ color: theme.palette.success.main }}>
                            {values.discount}
                            {`${translate('memberships.givenDiscount')}`}(
                            {fPercent((1 - parseFloat(values?.discount || '1')) * 100, false)})
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h5" sx={{ color: theme.palette.success.main }}>
                            {`${fCurrency(
                              -(
                                price *
                                values?.total_hours *
                                parseFloat((1 - parseFloat(values?.discount || '1')).toFixed(2))
                              )
                            )} ${getCurrencyLabel(values.currency)}`}
                          </Typography>
                        </Grid>
                      </>
                    )} */}
                    {/* {referral && values?.total_hours > 6 && (
                      <>
                        <Grid item xs={8}>
                          <Typography sx={{ color: theme.palette.success.main }}>
                            {`${translate('memberships.referralDiscount')}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h5" sx={{ color: theme.palette.success.main }}>
                            {`${fCurrency(
                              -parseInt(referral.code.split('-')[0])
                            )} ${getCurrencyLabel(values.currency)}`}
                          </Typography>
                        </Grid>
                      </>
                    )} */}
                  </>
                )}

                <Grid item xs={8} mt={2}>
                  <Typography color={alpha(theme.palette.common.black, 0.75)}>{`${translate(
                    'memberships.total'
                  )}`}</Typography>
                </Grid>
                <Grid item xs={4} mt={2}>
                  <Typography variant="h4">
                    {values?.discount === '' || !values?.discount
                      ? `${fCurrency(
                          (price_compare + values?.extra_student * 50 || 0) *
                            discountvisual *
                            values?.total_hours
                          // - parseInt(referral?.code.split('-')[0] || '0')
                        )} ${getCurrencyLabel(values.currency)}`
                      : `${fCurrency(
                          (price *
                            values?.total_hours *
                            (100 - parseFloat(values?.discount || '1'))) /
                            100
                        )} ${getCurrencyLabel(values.currency)}`}
                  </Typography>
                </Grid>
                {values?.additional_discount > 0 && (
                  <>
                    <Grid item xs={8} mt={2}>
                      <Typography color={alpha(theme.palette.common.black, 0.75)}>{`${translate(
                        'memberships.totalFirstMonth'
                      )}`}</Typography>
                    </Grid>
                    <Grid item xs={4} mt={2}>
                      <Typography variant="h4">
                        {values?.discount === '' || !values?.discount
                          ? `${fCurrency(
                              (price_compare + values?.extra_student * 50 || 0) *
                                discountvisual *
                                values?.total_hours -
                                values.additional_discount
                              // - parseInt(referral?.code.split('-')[0] || '0')
                            )} ${getCurrencyLabel(values.currency)}`
                          : `${fCurrency(
                              (price *
                                values?.total_hours *
                                (100 - parseFloat(values?.discount || '1'))) /
                                100 -
                                values.additional_discount
                            )} ${getCurrencyLabel(values.currency)}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                      <Typography mt={1} sx={{ color: theme.palette.success.main }}>
                        {`${translate('memberships.additionalDiscount')}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} mt={2}>
                      <Typography variant="h5" sx={{ color: theme.palette.success.main }}>
                        -{fCurrency(values.additional_discount)} {getCurrencyLabel(values.currency)}
                      </Typography>
                    </Grid>
                  </>
                )}

                {invoice?.status == 'pending' || !invoice ? (
                  <>
                    {!admin && (
                      <Grid item xs={12} mt={2}>
                        <Checkbox
                          value={acceaptTerms}
                          onChange={() => setAcceptTerms(!acceaptTerms)}
                          required
                          sx={{ p: 0 }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: 15,
                            p: (theme) => theme.spacing(0.75),
                          }}
                        >
                          <Link
                            sx={{ color: alpha(theme.palette.common.black, 0.75) }}
                            onClick={() => {
                              if (invoice?.market_id === '2') {
                                window.open(
                                  'https://www.toptutors.com/es-es/terminos-y-condiciones'
                                );
                              } else {
                                window.open('https://www.toptutors.com/betingelser');
                              }
                            }}
                          >
                            {`${translate('memberships.acceptTCs')}`}
                          </Link>
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <LoadingButton
                        type="submit"
                        color="success"
                        variant="contained"
                        size="large"
                        disabled={!acceaptTerms}
                        endIcon={<Iconify icon="ph:caret-right" />}
                        style={{
                          border: theme.palette.borders.border,
                          boxShadow: theme.palette.borders.boxShadow,
                          borderRadius: theme.palette.borders.borderRadius,
                        }}
                        sx={{
                          mt: 2,
                          mb: 5,
                          width: '100%',
                          height: '50px',
                          '&.Mui-disabled': {
                            background: alpha(theme.palette.success.main, 0.4),
                            color: theme.palette.success.contrastText,
                          },
                        }}
                      >
                        {admin
                          ? `${translate('memberships.sendOrder')}`
                          : `${translate('memberships.confirmMembership')}`}
                      </LoadingButton>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    {/* <Typography
                      sx={{ backgroundColor: invoice?.status === 'paid' ? 'primary' : 'darkred' }}
                    >
                      {invoice?.status.toUpperCase()}
                    </Typography> */}
                  </Grid>
                )}
              </Grid>
            </Box>
            <Guarantee />
          </Card>
        </Grid>
        {/* {admin ? (
          <Grid sx={{ marginTop: 4 }} item xs={12} md={12}>
            <Typography id="non-linear-slider" gutterBottom>
              Leaving Penalty: {churnPenalty}
            </Typography>
            <Slider
              sx={{ height: '15px' }}
              size="medium"
              aria-label="Always visible"
              max={values?.installments}
              defaultValue={values?.installments}
              onChange={(event) => {
                setChurnPenalty(parseInt((event?.target as HTMLInputElement).value));
              }}
              step={1}
              valueLabelDisplay="on"
            />
          </Grid>
        ) : (
          <></>
        )} */}
      </Grid>
    </FormProvider>
  );
}
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(2),
    // border: '1px solid #dadde9',
    border: theme.palette.borders.border,
    boxShadow: theme.palette.borders.boxShadow,
    borderRadius: theme.palette.borders.borderRadius,
  },
}));

interface CommitmentProps {
  market_id?: string;
}

export function Commitment({ market_id }: CommitmentProps) {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack direction="column" alignItems="center" sx={{ my: 3 }}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Iconify icon="ph:seal-check-bold" color={theme.palette.success.main} width={25} />
        <Typography variant="h5" color={theme.palette.success.main} sx={{ ml: 0.5 }}>
          {`${translate('memberships.commitmentTitle')}`}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center">
        {market_id === '1' ? (
          <HtmlTooltip
            title={
              <>
                <React.Fragment>
                  <Typography color="inherit" variant="h6">{`${translate(
                    'memberships.commitmentTooltipTitle'
                  )}`}</Typography>
                  <br />
                  <ul style={{ paddingLeft: theme.spacing(2) }}>
                    {Array.from({ length: 8 }).map((_, index) => (
                      <li key={index}>
                        {String(translate(`memberships.commitmentTooltipPrice${index + 1}DK`))}
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              </>
            }
          >
            <Typography style={{ width: '75%' }} variant="body2" textAlign="center">
              {`${translate('memberships.commitmentBody')}`}
            </Typography>
          </HtmlTooltip>
        ) : (
          <Typography style={{ width: '75%' }} variant="body2" textAlign="center">
            {`${translate('memberships.commitmentBody')}`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export function InformationBox() {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      sx={{
        my: 3,
        mx: 3,
        backgroundColor: theme.palette.info.main,
        borderRadius: '16px',
        p: theme.spacing(2),
      }}
    >
      <Stack direction="row" alignItems="center">
        <Iconify icon="ph:info" color={theme.palette.info.highlightText} width={25} />
        <Typography variant="body1" color={theme.palette.info.highlightText} sx={{ ml: 0.5 }}>
          {`${translate('memberships.infoHeader')}`}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="caption" color={theme.palette.info.contrastText} sx={{ mt: 1 }}>
          {`${translate('memberships.selectMembershipBody')}`}
        </Typography>
      </Stack>
    </Stack>
  );
}
