import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer, useTheme } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import LogoIcon from '../../../components/logoicon';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavDocs from './NavDocs';
import { useLocales } from '../../../locales';
import { dispatch as dispatchAction } from '../../../redux/store';
import { stopLoading } from 'src/redux/slices/notes';
import { useSelector } from 'src/redux/store';
import { m } from 'framer-motion';
// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

const COLLAPSED_WIDTH = 80;
const EXPANDED_WIDTH = NAV.W_DASHBOARD;
const HOVER_ZONE_SIZE = 20; // Size of invisible hover detection zone
const TRANSITION_DURATION = 0.3;
const TRANSITION_EASE = [0.4, 0, 0.2, 1]; // Material UI's easeInOut

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();
  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'lg');

  const { user } = useAuthContext();
  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (isDesktop && pathname !== '/document') setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (isDesktop && pathname !== '/document') setIsHovered(false);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 10,
          px: isDesktop && !isHovered ? 1 : 2.5,
          flexShrink: 0,
          transition: 'padding 0.3s ease',
        }}
      >
        {pathname === '/ai' || pathname === '/document' || (isDesktop && !isHovered) ? (
          <LogoIcon redirectPath={user?.freemium_signup ? '/ai' : '/dashboard'} />
        ) : (
          <Logo redirectPath={user?.freemium_signup ? '/ai' : '/dashboard'} />
        )}
      </Stack>

      <NavSectionVertical data={navConfig(user, translate)} isCollapsed={isDesktop && !isHovered} />

      <Box sx={{ flexGrow: 1 }} />

      {pathname !== '/ai' && pathname !== '/document' && isHovered && <NavDocs />}
    </Scrollbar>
  );
  const { note } = useSelector((slice) => slice.notes);
  if (!isDesktop) {
    return (
      <Drawer
        open={openNav}
        onClose={onCloseNav}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: { width: NAV.W_DASHBOARD },
        }}
      >
        {navContent}
      </Drawer>
    );
  }

  // Desktop permanent nav
  return (
    <Box
      component={m.nav}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      animate={{
        width: isHovered ? EXPANDED_WIDTH : COLLAPSED_WIDTH,
      }}
      transition={{
        duration: TRANSITION_DURATION,
        ease: TRANSITION_EASE,
      }}
      sx={{}}
    >
      <Drawer
        open
        variant={'permanent'}
        PaperProps={{
          component: m.div,
          animate: {
            width: isHovered ? EXPANDED_WIDTH : COLLAPSED_WIDTH,
          },
          transition: {
            duration: TRANSITION_DURATION,
            ease: TRANSITION_EASE,
          },
        }}
      >
        {navContent}
      </Drawer>
    </Box>
  );
}
