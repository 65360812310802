import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Tooltip, Link, ListItemText, Chip, useTheme } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
//
import Iconify from '../../iconify';
//
import { NavItemProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import useResponsive from 'src/hooks/useResponsive';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  isCollapsed = false,
  ...other
}: NavItemProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const { pathname } = useLocation();

  const { title, path, icon, info, children, disabled, caption, roles, markets, freemium, isNew } =
    item;

  const subItem = depth !== 1;

  const itemContent = (
    <m.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.2,
        delay: depth * 0.1,
        ease: 'easeOut',
      }}
    >
      <StyledItem
        depth={depth}
        active={active}
        disabled={disabled}
        caption={!!caption}
        {...other}
        sx={{
          ...(isCollapsed && {
            px: 1,
            width: 'auto',
            justifyContent: 'center',
            '& .MuiChip-root': { display: 'none' },
          }),
        }}
      >
        {icon && (
          <>
            <StyledIcon
              sx={{
                ...(isCollapsed && {
                  mr: 0,
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }),
              }}
            >
              {icon}
            </StyledIcon>
          </>
        )}

        {!isCollapsed && (
          <>
            <ListItemText
              primary={`${translate(title)}`}
              secondary={
                caption && (
                  <Tooltip title={`${translate(caption)}`} placement="top-start">
                    <span>{`${translate(caption)}`}</span>
                  </Tooltip>
                )
              }
              primaryTypographyProps={{
                noWrap: true,
                component: 'span',
                variant: active ? 'subtitle2' : 'body2',
              }}
              secondaryTypographyProps={{
                noWrap: true,
                variant: 'caption',
              }}
            />

            {info && (
              <Box component="span" sx={{ lineHeight: 0 }}>
                {info}
              </Box>
            )}
            {/* Show chip of new feature when sidebar is open */}
            {isNew && isDesktop && pathname !== '/ai' && pathname !== '/document/home' && (
              <Chip
                label={`${translate('overview.new')}!`}
                size="small"
                color="primary"
                sx={{
                  height: 18,
                  fontSize: 11,
                  borderRadius: 1,
                  minWidth: 'auto',
                  // backgroundColor: 'primary.lighter',
                  background: `linear-gradient(135deg, ${theme.palette.success.main} 0%, ${theme.palette.primary.main} 100%)`,
                  color: 'primary.white',
                  '& .MuiChip-label': {
                    px: 1,
                    lineHeight: '14px',
                  },
                }}
              />
            )}
            {!!children && (
              <>
                <Iconify
                  width={16}
                  icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                  sx={{ ml: 1, flexShrink: 0 }}
                />
              </>
            )}
          </>
        )}
      </StyledItem>
    </m.div>
  );

  const renderItem = () => {
    // Wrap with tooltip when collapsed
    const wrappedContent = isCollapsed ? (
      <Tooltip title={`${translate(title)}`} placement="right" arrow>
        {itemContent}
      </Tooltip>
    ) : (
      itemContent
    );

    // ExternalLink
    if (isExternalLink) {
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {wrappedContent}
        </Link>
      );
    }

    // Has child
    if (children) {
      return wrappedContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {wrappedContent}
      </Link>
    );
  };

  return (
    <RoleBasedGuard freemium={freemium} roles={roles} markets={markets}>
      {renderItem()}
    </RoleBasedGuard>
  );
}
