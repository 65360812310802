// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// react
import { useDispatch, useSelector } from '../../../redux/store';
import {
  getCustomerLatestOrder,
  getLatestNonMembershipOrder,
} from '../../../redux/slices/customer';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';
import { CustomerCredits } from '../../../sections/@dashboard/membership';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { useAuthContext } from '../../../auth/useAuthContext';
import { SelectMarket } from '../../../sections/market';
import PurchaseMembershipWidget from './PurchaseMembershipWidget';
import { ChatHistory } from '../../../sections/@dashboard/ai_helper';
import { getAiChatHistoryList, fetchAIChatHistory } from '../../../redux/slices/ai';

// models
// import { onboardingCards } from 'src/assets/data';
// import OnboardingPopUp from 'src/sections/@dashboard/components/OnboardingPopUp';

// import NotificationsPopover from './NotificationsPopover';
// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { user, updateMarket } = useAuthContext();
  const isDesktop = useResponsive('up', 'lg');
  const dispatch = useDispatch();
  // chat history list
  const { chatHistory } = useSelector((state) => state.ai);

  // Function to handle opening chat history
  const handleGetHistory = () => {
    dispatch(getAiChatHistoryList(user?.uid)); // Fetch the chat history
  };
  const { latestCustomerOrder, latestNonMembershipOrder } = useSelector((state) => state.customer);
  useEffect(() => {
    if (user?.customer_dict?.id) {
      if (
        !latestCustomerOrder ||
        latestCustomerOrder.customer_id !== user?.customer_dict?.id.toString()
      ) {
        dispatch(getCustomerLatestOrder(parseInt(user?.customer_dict?.id, 10)));
        dispatch(getLatestNonMembershipOrder(parseInt(user?.customer_dict?.id, 10)));
      }
    }
  }, [dispatch, user?.customer_dict?.id]);

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP);
  const handleFetchChatAiHistory = async (chatId: number) => {
    // console.log(chatId);

    const response = await dispatch(fetchAIChatHistory(chatId));
    if (response) {
      console.log();
    } else {
      console.log();
    }
  };
  const renderContent = (
    <>
      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="ph:list" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 1, sm: 3 }}
      >
        {(user?.customer || user?.student) && pathname !== '/document' && (
          <>
            {/* TODO: Have user property is_premium as the second check instead of unverified_freemium_user */}
            {/* {!user?.has_seen_premium_onboarding && !user?.unverified_freemium_user && (
              <OnboardingPopUp
                open={open}
                setOpen={setOpen}
                isPremium={true}
                cardArray={onboardingCards.premium}
              />
            )} */}

            {!user?.freemium_signup && (
              <PurchaseMembershipWidget monthly_credits_left={user?.monthly_credits_left || 0} />
            )}
            <CustomerCredits
              user={user}
              user_credits={user?.monthly_credits_left || 0}
              latestCustomerOrder={latestCustomerOrder}
              latestNonMembershipOrder={latestNonMembershipOrder}
            />
          </>
        )}
        <LanguagePopover />

        {/* <NotificationsPopover /> */}
        {user?.superadmin && <SelectMarket user_id={user?.uid} updateMarket={updateMarket} />}
        {pathname === '/ai' && (
          <ChatHistory
            handleGetHistory={handleGetHistory}
            aiChatList={chatHistory}
            fetchAIChatHistory={handleFetchChatAiHistory}
          />
        )}
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height', 'width'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: '100%',
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
