import { Theme } from '@mui/material/styles';
import { TFunction } from 'i18next';

export const subjects_dk = [
  {
    label: 'Matematik',
    icon: '📈',
    value: 'math',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.math',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Dansk',
    icon: '🇩🇰',
    value: 'danish',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.danish',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Engelsk',
    icon: '🇬🇧',
    value: 'english',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.english',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Biologi',
    icon: '🧬',
    value: 'biology',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.biology',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Fysik/kemi',
    icon: '🧪',
    value: 'physics/chemistry',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.chemistry',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Afsætning',
    icon: '🇩🇰',
    value: 'afsætning',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.afsætning',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Virksomhedsøkonomi',
    icon: '🇩🇰',
    value: 'virksomhedsøkonomi',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.virksomhedsøkonomi',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Bioteknologi',
    icon: '🇩🇰',
    value: 'bioteknologi',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.bioteknologi',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Tysk',
    icon: '🇩🇪',
    value: 'german',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.german',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Spansk',
    icon: '🇪🇸',
    value: 'spanish',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.spanish',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Fransk',
    icon: '🇫🇷',
    value: 'french',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.french',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Samfundsfag',
    icon: '⚖️',
    value: 'socialstudies',
    disabled: false,
    ai_tutor: true,
    soon: false,
    translateLabel: 'register.subjectsDk.socialStudies',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Historie',
    icon: '📚',
    value: 'history',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.history',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Geografi',
    icon: '🌏',
    value: 'geography',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.geography',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Natur/teknik',
    icon: '🐢',
    value: 'nature/technologi',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.natureAndTechnology',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },

  // {
  //   label: 'Erhvervsjura',
  //   icon: '🇩🇰',
  //   value: 'erhvervsjura',
  //   disabled: false,
  //   ai_tutor: true,
  //   translateLabel: 'register.subjectsDk.erhvervsjura',
  //   translateDisabled: 'register.subjects.comingSoon',
  // },
  // {
  //   label: 'Psykologi',
  //   icon: '🇩🇰',
  //   value: 'psykologi',
  //   disabled: false,
  //   ai_tutor: true,
  //   translateLabel: 'register.subjectsDk.psykologi',
  //   translateDisabled: 'register.subjects.comingSoon',
  // },
];

export const interests_dk = [
  { label: 'Football', icon: '⚽️' },
  { label: 'Swimming', icon: '🏊' },
  { label: 'Gaming', icon: '🎮' },
  { label: 'Reading', icon: '📚' },
  { label: 'Boxing', icon: '🥊' },
  { label: 'Painting', icon: '🎨' },
  { label: 'Movies', icon: '🎬' },
  { label: 'Skating', icon: '🛹' },
  { label: 'Handball', icon: '🤾' },
  { label: 'Photography', icon: '📸' },
  { label: 'Basketball', icon: '🏀' },
  { label: 'Music', icon: '🎧' },
  { label: 'Ice Hockey', icon: '🏒' },
  { label: 'Tennis', icon: '🎾' },
  { label: 'Biking', icon: '🚴' },
  { label: 'Table Tennis', icon: '🏓' },
  { label: 'Skiing', icon: '⛷' },
  { label: 'Badminton', icon: '🏸' },
  { label: 'Golf', icon: '⛳️' },
  { label: 'Horse Riding', icon: '🏇' },
  { label: 'Bowling', icon: '🎳' },
  { label: 'Running', icon: '👟' },
  { label: 'Crochet', icon: '🧶' },
  { label: 'Drawing', icon: '✍️' },
  { label: 'Cooking', icon: '🍲' },
  { label: 'Hiking', icon: '🏔' },
  { label: 'Fishing', icon: '🎣' },
  { label: 'Sewing', icon: '🪡' },
  { label: 'Karate', icon: '🥋' },
  { label: 'Puzzles', icon: '🧩' },
];

export const getMemberships = (theme: Theme, translate: TFunction) => [
  {
    title: translate('memberships.unstoppable.title'),
    icon: 'heroicons:trophy',
    code: '12',
    value: 12,
    label: translate('memberships.unstoppable.label'),
    description: translate('memberships.unstoppable.desc'),
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    standard: true,
    priceRange: '209-249 kr. per time',
    allowedConnections: 3,
    attentionText: translate('memberships.unstoppable.attentionText'),
    attentionTextColor: theme.palette.warning.contrastText,
    attentionBackgroundColor: theme.palette.warning.main,
  },
  {
    title: translate('memberships.driven.title'),
    icon: 'majesticons:shooting-star-line',
    code: '8',
    label: translate('memberships.driven.label'),
    description: translate('memberships.driven.desc'),
    value: 8,
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    standard: true,
    priceRange: '219-269 kr. per time',
    attentionText: translate('memberships.driven.attentionText'),
    allowedConnections: 2,
    attentionTextColor: theme.palette.success.contrastText,
    attentionBackgroundColor: theme.palette.success.main,
  },
  {
    title: translate('memberships.casual.title'),
    icon: 'material-symbols:conversion-path',
    code: '6',
    label: translate('memberships.casual.label'),
    description: translate('memberships.casual.desc'),
    value: 6,
    package_info: '- 1 tutor, 1 elev, 1 subject',
    priceRange: '229-299 kr. per time',
    allowedConnections: 1,
    standard: true,
  },
  {
    code: '4',
    value: 4,
    label: '1 time om ugen',
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    standard: false,
  },
  {
    code: '16',
    value: 16,
    label: '4 timer om ugen',
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    standard: false,
  },
  {
    code: '24',
    value: 24,
    label: '6 timer om ugen',
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    standard: false,
  },
];
